if (Modernizr.touch === true && $(window).width() <= 767) {
  //alert('Touch Screen');
} else {
}

// for carousel ==========================
// $('#screenshots').on('slide.bs.carousel', function(e) {
//   /*
//   CC 2.0 License Iatek LLC 2018
//   Attribution required
//   */
//   var $e = $(e.relatedTarget)
//   var idx = $e.index()
//   console.log('index', idx)

//   var itemsPerSlide = 3
//   var totalItems = $('#screenshots > .carousel-inner > .carousel-item').length

//   if (idx >= totalItems - (itemsPerSlide - 1)) {
//     var it = itemsPerSlide - (totalItems - idx)
//     for (var i = 0; i < it; i++) {
//       // append slides to end
//       if (e.direction == 'left') {
//         console.log('it')

//         $('#screenshots > .carousel-inner > .carousel-item')
//           .eq(i)
//           .appendTo('#screenshots > .carousel-inner')
//       } else {
//         $('#screenshots > .carousel-inner > .carousel-item')
//           .eq(0)
//           .appendTo('#screenshots > .carousel-inner')
//       }
//     }
//   }
// })

$(document).ready(function() {
  new WOW().init()
  $('#owl-carousel-screenshot').owlCarousel({
    loop: true,
    margin: 10,
    responsiveClass: true,
    slideTransition: 'ease',
    autoplayTimeout: 6000,
    autoplaySpeed: 6000,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      600: {
        items: 3,
        nav: false
      },
      1000: {
        items: 4,
        nav: true,
        margin: 20
      }
    }
  })

  $('#owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    responsiveClass: true,
    dots: false,
    slideTransition: 'ease',
    autoplayTimeout: 6000,
    autoplaySpeed: 6000,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      600: {
        items: 4,
        nav: false
      },
      1000: {
        items: 4,
        nav: false,
        margin: 20
      }
    }
  })
})

var $header = $('.main')
  .children('div:first-of-type')
  .children()
$header.css('visibility', 'hidden').addClass('fadeIn')
var $div = $('.main')
  .children('div:not(:first-of-type)')
  .children()
$div.css('visibility', 'hidden').addClass('fadeIn')
;(function($) {
  'use strict'

  /* ==================================================
  # Get scroll bar width
  ===================================================*/
  function getBarwidth() {
    // Create the measurement node
    let scrollDiv = document.createElement('div')
    scrollDiv.className = 'scrollbar-measure'
    document.body.appendChild(scrollDiv)

    // Get the scrollbar width
    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    //console.warn(scrollbarWidth); // Mac:  15

    // Delete the DIV
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }

  /* ==================================================
  # Smooth Scroll
  ===================================================*/
  function scrollToAnchor() {
    $('.js-scroll-to').on('click', function(event) {
      var $anchor = $(this)
      var headerH = '0'
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr('href')).offset().top - headerH + 'px'
          },
          1000
        )
      event.preventDefault()
    })
  }

  function init() {
    scrollToAnchor()
    getBarwidth()
  }

  $(document).ready(function() {
    init()
    setTimeout(function() {
      $header.each(function() {
        $(this)
          .css('visibility', 'visible')
          .css('opacity', '1')
      })
    }, 1000)
    $(window).scroll(function() {
      for (var i = 0; i < $div.length; i++) {
        var elementToTopWindow = $div[i].offsetTop - $(window).scrollTop()
        if (elementToTopWindow < $(window).height() - 200) {
          $($div[i])
            .css('visibility', 'visible')
            .css('opacity', '1')
        }
      }
    })
    $('.humburger').click(function() {
      $('html').addClass('active-humburger')
      if ($(this).hasClass('active-humburger')) {
        $(this).removeClass('active-humburger')
        $('.nav-main').slideUp(200)
        $('html').removeClass('active-humburger')
      } else {
        $(this).addClass('active-humburger')
        $('.nav-main').slideDown(200)
        $('html').addClass('active-humburger')
      }
    })

    // $('html').mCustomScrollbar();

    setTimeout(() => {
      $('.preloader').addClass('loaded')
    }, 500)
  }) // end document ready function

  // $(window).on('scroll', function() {
  // });

  // if ($('.x-toTop').length) {
  //   var scrollTrigger = 100, // px
  //     backToTop = function () {
  //       var scrollTop = $(window).scrollTop();
  //       if (scrollTop > scrollTrigger) {
  //         $('.x-toTop').addClass('active');
  //       } else {
  //         $('.x-toTop').removeClass('active');
  //       }
  //     };
  //   backToTop();
  //   $(window).on('scroll', function () {
  //     backToTop();
  //   });
  // };
})(jQuery) // End jQuery
